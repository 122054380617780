import { Col, Layout, Row } from "antd";
const { Footer } = Layout;

export default function MenuFooter() {
  const year = new Date().getFullYear();
  return (
    <Footer className="da-bg-color-black-10 da-bg-color-dark-100">
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <p className="da-badge-text da-mb-0 da-text-color-dark-30">
            COPYRIGHT ©{year}, All rights Reserved
          </p>
        </Col>
      </Row>
    </Footer>
  );
};