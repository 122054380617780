import axios from 'axios';

/** Test Series Start */

export const ListTestSeriessApiService = (data = {}) => {
   return axios.get(`test-series`, {
      params: {
         paginate: data.paginate || 0,
         page: data.page || 1,
         per_page: data.per_page || 10,
         segment_id: data.segment_id || undefined
      },
   });
};

export const ViewTestSeriesApiService = (id) => {
   return axios.get(`test-series/${id}`);
};

export const AddTestSeriesApiService = (data) => {
   return axios.post('test-series', data);
};

export const UpdateTestSeriesApiService = (testSeriesId, data) => {
   return axios.post(`test-series/${testSeriesId}`, data);
};

/** Test Series End */

// ****************************************************************************** //

/** Section Start */

export const ViewSectionInTestSeriesApiService = (id) => {
   return axios.get(`test-series/session/${id}`);
};

export const AddSectionsToTestSeriesApiService = (params, data) => {
   const { id: testSeriesId } = params;
   return axios.post(`test-series/${testSeriesId}/session`, data);
};

export const UpdateSectionsToTestSeriesApiService = (sectionId, data) => {
   return axios.post(`test-series/session/${sectionId}`, data);
};

export const RemoveSectionsFromTestSeriesApiService = (sectionId) => {
   return axios.delete(`test-series/session/${sectionId}`);
};

export const ChangeSectionOrderInTestSeriesApiService = (params, data) => {
   const { id: testSeriesId } = params;
   return axios.post(`test-series/${testSeriesId}/order-sessions`, data);
};

/** Section End */

// ****************************************************************************** //

/** Test Start */

export const AddTestToSectionsApiService = (sectionId, data) => {
   return axios.post(`test-series/session/${sectionId}/add-tests`, data);
};

export const RemoveMockTestFromSectionApiService = (id, data) => {
   return axios.post(`test-series/session/${id}/remove-tests`, data);
};

export const ChangeTestSeriesOrderInSectionsApiService = (params, data) => {
   const { id: testSeriesId } = params;
   return axios.post(`test-series/session/${testSeriesId}/order-tests`, data);
};

/** Test End */