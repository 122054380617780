import axios from "axios";

export const ListAllProductApiService = (data = {}) => {
  return axios.get(`products`, {
    params: {
      paginate: data.paginate || 0,
      page: data.page || 1,
      per_page: data.per_page || 10,
    },
  });
};

export const ListAllProductWithoutPageApiService = () => {
  return axios.get(`products`);
};

export const ListFacultyCourses = () => {
  return axios.get(`/v2/products`);
};

export const getProductDetailsApiService = (productId) => {
  return axios.get(`products/${productId}`);
};

export const GetProductWithQuestionsApiService = (productId) => {
  return axios.get(`products/${productId}/questions`);
};

export const GetAllQuestionsApiService = (params = {}) => {
  return axios.get(`questions`, {
    params,
  });
};
