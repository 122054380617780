import axios from 'axios';

export const ListGradesApiService = (id,data = {}) => {
    return axios.get(`tests/${id}/grades`, {
        paginate: data.paginate || 0
    });
}

export const CreateGradeApiService = (data) => {
    return axios.post(`test-grades`, data);
}

export const UpdateGradeApiService = (id, data) => {
    return axios.post(`test-grades/${id}`, data);
}

export const DeleteGradeApiService = (id) => {
    return axios.delete(`test-grades/${id}`);
}