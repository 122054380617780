import { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd'
// import { Link } from 'react-router-dom';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    goBack = (e) => {
        window.location.reload()
    };

    render() {
        if (this.state.hasError) {
            return (
                <Row align="center" justify="center" style={{ textAlign:"center", "height": "60vh", "alignItems":"center" }} >
                    <div>
                        <h3 className="da-mb-16" style={{ color: "#890808"}}>Something went wrong.</h3>
                        <p className="da-mb-8">The page you are looking for does not exist or other error occured.</p>
                        <p className="da-mb-8">
                            <a href="#" onClick={this.goBack}>
                                Reload
                            </a>
                            , or head over to <a href={'/auth/login'}>Login</a> to choose a new direction.
                        </p>
                    </div>
                </Row>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
};

export default ErrorBoundary;
