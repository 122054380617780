import customiseReducer from './customise/customiseReducer';
import previewReducer from './preview/previewReducer';
import generalReducer from './general/generalReducer';
import { combineReducers } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
   customise: customiseReducer,
   preview: previewReducer,
   general: generalReducer,
});

export default rootReducer;
