import axios from 'axios';

export const ListBannerApiService = (data = {}) => {
    return axios.get(`banners`, {
        params: {
            paginate: data.paginate || 0,
            type: data?.type || null
        }
    });
}

export const CreateBannerApiService = (data) => {
    return axios.post(`banners`, data);
}

export const UpdateBannerApiService = (id, data) => {
    return axios.post(`banners/${id}`, data);
}

export const DeleteBannerApiService = (id) => {
    return axios.delete(`banners/${id}`);
}