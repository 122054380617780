import { Engagespot } from "@engagespot/react-component";
import { useEffect } from "react";
import { useState } from "react";
import {
  EngagespotApiService,
  EngagespotInAppFalseApiService,
} from "services/api/engagespot";
import { Avatar, Col, Dropdown, Menu, Row } from "antd";
import { showToast } from "helpers";
import { GoogleMeetJoinLink } from "services/api/google-meet";
// import avatarImg from "../../../assets/images/memoji/memoji-1.png";

const Notification = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [falseData, setFalseData] = useState(null);
  console.log("hello");

  const menu = (
    <Menu theme={"light"}>
      {falseData?.data.map((notif, index) => {
        return (
          <Menu.Item key={index}>
            <Col>
              <Row>{notif?.title}</Row>
              <Row>{notif?.body}</Row>
            </Col>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const theme = {
    colors: {
      brandingPrimary: "#03001C",
    },
    notificationButton: {
      iconFill: "#03001C",
      hoverBackground: "",
      borderWidth: "10px",
    },
    feedItem: {
      headerColor: "#03001C",
      placeHolderBackground: "",
    },
    header: {
      background: "#03001C",
      fontColor: "#fff",
      closeButtonBackground: "",
      fontSize: "",
      fontWeight: "bold",
      padding: "10px 15px",
      closeButtonOutline: "",
      closeButtonPadding: "",
      closeButtonFontSize: "",
      closeButtonMargin: "",
    },
    footer: {
      preferenceButtonColor: "",
    },
  };

  const fetchItems = async () => {
    try {
      setLoading(true);
      let response = await EngagespotApiService();
      setData(response);
      console.log(response, "res");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const fetchItemsFalse = async () => {
    try {
      setLoading(true);
      let response = await EngagespotInAppFalseApiService();
      setFalseData(...response);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const apiKey = data?.api_key;
  const inApp = data?.inApp;
  const userId = String(data?.user_id);
  const signature = data?.signature;

  console.log(apiKey, inApp, userId, signature, "values");

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (!inApp) {
      fetchItemsFalse();
    }
  }, [inApp]);

  useEffect(() => {
    window.localStorage.setItem("apiKey", apiKey);
  }, [apiKey]);

  return (
    <>
      {userId && signature && apiKey && (
        <Engagespot
          apiKey={apiKey}
          theme={theme}
          userId={userId}
          userSignature={signature}
          eventListenersToRun={[
            {
              // Mentor class join
              blockId: "mentorship-session-reminder_b0_state_1_i0",
              event: "onClick",
              onEvent: async ({ notification, changeNotificationState }) => {
                try {
                  let response = await GoogleMeetJoinLink(
                    notification?.data?.session_id
                  );
                  const meetUrl = response?.data?.meet_url;
                  if (meetUrl) {
                    window.open(meetUrl, "_blank");
                  }
                } catch (e) {
                  console.log(e);
                  showToast({ defaultToErrorMsg: true, message: e.error });
                }
                changeNotificationState({ state: "state_2" });
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default Notification;
