import axios from 'axios';

export const UploadQuestionBankApiService = (data) => {
    //Form Data - segment_id, product_id, chapter_id, file (docx, doc)

    return axios.post("questions/upload",data);
}

export const GetQuestionByIdApiService = (id) => {
    //Form Data - segment_id, product_id, chapter_id, file (docx, doc)

    return axios.get("questions/"+id);
}
export const UpdateQuestionByIdApiService = (id, data) => {
    //Form Data - segment_id, product_id, chapter_id, file (docx, doc)

    return axios.post("questions/"+id, data);
}

export const AddQuestionApiService = (data) => {
    //Form Data - segment_id, product_id, chapter_id, file (docx, doc)

    return axios.post("questions", data);
}
export const DeleteQuestionApiService = (data) => {
    return axios.post("questions/delete", data);
}
export const ReportedQuestionApiService = () => {
    return axios.get("questions/reports");
}

export const ResolveReportedQuestionApiService = (data) => {
    return axios.post(`questions/reports/update`, data);
}

export const UploadQuestionMultipleBankApiService = (data) => {
    //Form Data - segment_id, product_id, chapter_id, file (docx, doc)

    return axios.post("questions/create-multipple",data);
}