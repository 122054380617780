import axios from "axios";

export const ChangePasswordApiService = (id, data) => {
  return axios.post(`users/${id}/change-password`, data);
};

export const GetUserApiService = () => {
  return axios.get(`auth/me`);
};

export const ListAllUsers = (params = {}) => {
  return axios.get(`users`, {
    params,
  });
};

export const ListUserCourseReport = (params = {}) => {
  return axios.get(`users/export/learners-courses`, {
    params,
  });
};

export const ListUsersDetails = (id) => {
  return axios.get(`users/${id}`);
};

export const ListUsersDevices = (userId) => {
  return axios.get(`users/${userId}/devices`);
};

export const UpdateUsersDevicesStatus = (userId, deviceId, values) => {
  return axios.post(`users/${userId}/devices/${deviceId}`, values);
};

export const AddItemsToStudents = (data) => {
  return axios.post(`users/add-assigned-items`, data);
};

export const ListAllLearners = (productId) => {
  return axios.get(`users?private_product_id=${productId}`);
};
export const ListAllLearnersOfPrivateTest = (testId) => {
  return axios.get(`users?private_test_id=${testId}`);
};

export const RemoveLearners = (data) => {
  return axios.post(`users/remove-assigned-items`, data);
};

export const AddUsers = (data) => {
  return axios.post(`/clients/users/create`, data);
};
export const UpdateUsers = (userId, data) => {
  return axios.post(`/clients/users/${userId}/update`, data);
};

export const AddUsersViaUpload = (data) => {
  return axios.post(`clients/users/upload`, data);
};

export const DeleteUsers = (id) => {
  return axios.delete(`/users/${id}`);
};

export const SubscriptionDetailsWithPivot = (sub_id) => {
  return axios.get(`/users/subscription-details/${sub_id}`);
};

export const UpdateUserSubscriptionDetails = (user_id, sub_id, data) => {
  return axios.post(`/users/${user_id}/subscriptions/${sub_id}/update`, data);
};

export const UserViewDetails = (id) => {
  return axios.get(`/users/${id}/view`);
};
