import { useEffect } from "react";
import { useState } from "react";
import { localStorage } from 'reactive-localstorage';

const useLocalStorage = (key) => {
    const [val, setVal] = useState(localStorage.getItem(key));

    useEffect(() => {
        localStorage.on('change', (updatedKey, updatedVal) => {
            if(!updatedKey) {
                setVal('');
            } else if (updatedKey && updatedKey === key) {
                setVal(updatedVal);
            }
          });
    },[]);

    return val;
}

export { useLocalStorage }