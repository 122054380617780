import axios from "axios";

export const UploadImageApiService = (data, params) => {
  return axios.post(`upload/file`, data, { params });
};

export const AppcustomizationSaveApiService = (data) => {
  return axios.post(`clients/details`, data);
};

export const AppcustomizationGetApiService = () => {
  return axios.get(`clients/get-details`);
};

export const AddFeaturedApiService = (data) => {
  return axios.post(`featured-items/add-items`, data);
};

export const RemoveFeaturedApiService = (data) => {
  return axios.post(`featured-items/remove-items`, data);
};

export const ListNotificationIntergration = () => {
  return axios.get(`integrations/notification-methods?type=email`);
};

export const ListPaymentIntergration = () => {
  return axios.get(`integrations/payment-methods?payment_method=razorpay`);
};

export const ListLiveVideoIntergration = () => {
  return axios.get(`live-video-integration/1/view`);
};

export const ListGoogleMeetIntegration = () => {
  return axios.get(`/live-video-integration/2/view`);
};

export const SaveGoogleMeetIntegration = (data) => {
  return axios.post(`/live-video-integration/2`, data);
};
export const SaveLiveVideoIntergration = (data) => {
  return axios.post(`live-video-integration/1`, data);
};
export const SavePaymentIntergration = (data) => {
  return axios.post(`/integrations/payment-methods`, data);
};
export const SaveAnalyticsIntergration = (id, data) => {
  return axios.post(`/integrations/analytics/${id}`, data);
};
export const ListAnalyticsIntergration = (id) => {
  return axios.get(`integrations/analytics/${id}/view`);
};
export const SaveNotificationIntergration = (data) => {
  return axios.post(`/integrations/notification-methods`, data);
};
export const SaveVerificationEmail = (data) => {
  return axios.post(`/integrations/send-verification`, data);
};

export const SaveLeadsquaredIntergration = (id, data) => {
  return axios.post(`/integrations/crms/${id}`, data);
};
export const SaveHelpdeskIntergration = (id, data) => {
  return axios.post(`/integrations/helpdesk/${id}`, data);
};

export const GenerateChiperVideoOtp = (path, data) => {
  return axios.post(path, data);
};

export const GetZoomUsers = () => {
  return axios.get(`/zoom/users`);
};

export const ListEngageSpotIntergration = (id) => {
  return axios.get(`integrations/notification-methods?type=engagespot`);
};

export const ListEngageSpotEvents = () => {
  return axios.get(`/notifications/engagespot/events`);
};

export const AddEngageSpotEvents = (data) => {
  return axios.post(`notifications/engagespot/client-templates`, data);
};

export const ListClientEngageSpotEvents = (data) => {
  return axios.get(`notifications/engagespot/client-templates`, data);
};

export const ListLeadsquaredIntergration = (id) => {
  return axios.get(`integrations/crms/${id}/view`);
};

export const ListHelpDeskIntergration = (id) => {
  return axios.get(`integrations/helpdesk/${id}/view`);
};
