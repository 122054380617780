import axios from 'axios';

export const ListAdminUsersApiService = (data = {}) => {
    return axios.get(`/users/admin/list`, {
        params: {
            ...data,
            paginate: data.paginate || 0,
            page: data.per_page || 1,
            per_page: data.per_page || 1000
        },
    });
}

export const CreateAdminUsersApiService = (data = {}) => {
    return axios.post(`/users/admin`, data);
}

export const ViewAdminUsersApiService = (id) => {
    return axios.get(`/users/admin/${id}`);
}

export const EditAdminUsersApiService = (id, data = {}) => {
    return axios.patch(`/users/admin/${id}`, data);
}

export const DeleteAdminUsersApiService = (id) => {
    return axios.delete(`/users/admin/${id}`);
}

export const UpdateAdminStatusApiService = (id, status) => {
    return axios.patch(`/users/admin/${id}/change-status`,{
        status
    });
}

export const UpdateAdminApiService = (id, data = {}) => {
    return axios.patch(`/users/admin/${id}/update`, data);
} 