import axios from 'axios';

export const ListRolesApiService = (data = {}) => {
    return axios.get(`roles`, {
        params: {
            paginate: data.paginate || 0,
            page: data.page || 1,
            per_page: data.per_page || 10
        },
    });
}

export const CreateRoleApiService = (data = {}) => {
    return axios.post(`roles`, data);
}

export const ViewRoleApiService = (id) => {
    return axios.get(`roles/${id}`);
}

export const GetPermissionsApiService = () => {
    return axios.get(`permissions`);
}

export const getPermissionsForRoleApiService = (id) => {
    return axios.get(`roles/${id}/list-permissions`);
}

export const SetPermissionsApiService = (id, data) => {
    return axios.post(`roles/${id}/add-permissions`, data);
}

export const EditRoleApiService = (id,data) => {
    return axios.patch(`roles/${id}`, data);
}

export const DeleteRoleApiService = (id) => {
    return axios.delete(`roles/${id}`);
}

export const GetLoggedInUserPermissions = () => {
    return axios.get(`permissions/user`);
}