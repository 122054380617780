import createDebounce from 'redux-debounced';
import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
   key: 'root',
   storage,
   whitelist: ['preview', 'general'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
   reducer: persistedReducer,
   middleware: [createDebounce()],
});

export default store;
