import { AccessControl } from 'context/access-control.context';
import React from 'react';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Bag2, Category, Paper, Star, TwoUsers, Activity, PaperPlus, Chat, Chart, Notification, Search } from 'react-iconly';
import { AiFillFileText } from "react-icons/ai"

function useVerticalRoutes() {
   const [pages, setPages] = useState([]);
   const accessControlData = useContext(AccessControl)
   const isMentor = localStorage.getItem("isMentor");

   const filterByPermissions = (items, rbac) => {
      const userPermissions = rbac.permissions;
      const userRole = rbac.role;

      return items
        .map(item => {
          const newItem = { ...item };

          if(userRole === 'client') {
            return newItem;
          }
    
          if (newItem.children) {
            newItem.children = filterByPermissions(newItem.children, rbac);
          }

          if (!newItem.permissions || newItem.permissions.length === 0) {
            return newItem;
          }
    
          if (newItem.permissions.find(permission => userPermissions.includes(permission))) {
            return newItem;
          }
    
          if (newItem.children && newItem.children.length > 0) {
            return newItem;
          }
    
          return null;
        })
        .filter(item => item !== null);
    }

   useEffect(() => {
      if(accessControlData.role) {
         if (accessControlData.role === 'faculty') {
            let pages = [
               {
                 id: "MockTests",
                 title: "Assigned Tests",
                 icon: <Paper set="curved" className="remix-icon" />,
                 navLink: "/mock-test",
               },
               {
                 id: "question-bank",
                 title: "Assigned Subjects",
                 icon: <Bag2 set="curved" className="remix-icon" />,
                 navLink: "/question-bank",
               },
               {
                 id: "assigned-lessons",
                 title: "Assigned Lessons",
                 icon: <PaperPlus set="curved" className="remix-icon" />,
                 navLink: "/faculty/assigned-lessons",
               },
               isMentor === "true"
                 ? {
                     id: "Mentor-booking",
                     title: "Mentorship",
                     icon: <Paper set="curved" className="remix-icon" />,
                     children: [
                       {
                         id: "google-connect",
                         title: "Google Connect",
                         navLink: "/google-connect",
                       },
                       {
                         id: "sessions",
                         title: "Sessions",
                         navLink: "/bookings",
                       },
                       {
                         id: "available-time",
                         title: "Availability",
                         navLink: "/availability",
                       },
                     ],
                   }
                 : null,
               {
                 id: "discussion",
                 title: "Discussion",
                 icon: <TwoUsers set="curved" className="remix-icon" />,
                 navLink: "/faculty/discussion/chapters",
               },
             ].filter(Boolean);
   
            setPages(pages);
         } else {
            let pages = [
               {
                  id: 'Dashboard',
                  title: 'Dashboard',
                  icon: <Category set='curved' className='remix-icon' />,
                  navLink: '/dashboard',
                  permissions: []
               },
               {
                  id: 'Courses',
                  title: 'Courses',
                  icon: <Activity set='curved' className='remix-icon' />,
                  navLink: '/course',
                  permissions: ['course']
               },
               {
                  id: 'Segments',
                  title: 'Segments',
                  icon: <AiFillFileText set='curved' className='remix-icon' />,
                  navLink: '/segment',
                  permissions: ['segment']
               },
               {
                  id: 'MockTests',
                  title: 'Mock Tests',
                  icon: <Paper set='curved' className='remix-icon' />,
                  navLink: '/mock-test',
                  permissions: ['test']
               },
               // {
               //    id: 'TestSeries',
               //    title: 'Test Series',
               //    icon: <Star set='curved' className='remix-icon' />,
               //    navLink: '/test-series',
               // },
               {
                  id: 'question-bank',
                  title: 'Question Bank',
                  icon: <Bag2 set='curved' className='remix-icon' />,
                  navLink: '/question-bank',
                  permissions: ['questionBank']
               },
               {
                  id: 'manage',
                  title: 'Manage',
                  icon: <Category set='curved' className='remix-icon' />,
                  permissions: ['manage'],
                  children: [
                     {
                        id: 'subscriptions',
                        title: 'Subscriptions',
                        navLink: '/subscriptions',
                        permissions: ['manage_subscriptions__list']
                     },
                     {
                        id: 'banners',
                        title: 'Banners',
                        navLink: '/banners',
                        permissions: ['manage_banners__list']
                     },
                     {
                        id: 'cupons',
                        title: 'Coupons',
                        navLink: '/cupons',
                        permissions: ['manage_coupons__list']
                     },
                     {
                        id: 'app-customization',
                        title: 'App-customization',
                        navLink: '/app-customization',
                        permissions: ['manage_appCustomization__list']
                     },
                     {
                        id: 'integration',
                        title: 'Apps integration',
                        navLink: '/app-integration',
                        permissions: ['manage_appIntegration__list']
                     },
                     {
                        id: 'practice',
                        title: 'Practice',
                        navLink: '/practice',
                        permissions: ['manage_practice__list']
                     }
                  ],
               },
               {
                  id: 'users',
                  title: 'Users',
                  icon: <TwoUsers set='curved' className='remix-icon' />,
                  permissions: ['users'],
                  children: [
                     {
                        id: 'faculty',
                        title: 'Faculty',
                        navLink: '/faculty',
                        permissions: ['users_faculty']
                     },
                     {
                        id: 'user-details',
                        title: 'User Details',
                        navLink: '/user-details',
                        permissions: ['usersDetails']
                     },
                  ],
               },
               {
                  id: 'admin',
                  title: 'Admin',
                  icon: <TwoUsers set='curved' className='remix-icon' />,
                  permissions: ['admin'],
                  children: [
                     {
                        id: 'roles-and-permissions',
                        title: 'Roles and Permissions',
                        navLink: '/roles-and-permissions',
                        permissions: ['admin_roles']
                     },
                     {
                        id: 'admin-users',
                        title: 'User Details',
                        navLink: '/admin-users',
                        permissions: ['admin_users']
                     },
                  ],
               },
               {
                  id: 'reports',
                  title: 'Reports',
                  icon: <Chart set='curved' className='remix-icon' />,
                  permissions: ['reports'],
                  children: [
                     {
                        id: 'course',
                        title: 'Course',
                        navLink: '/reports/course',
                        permissions: ['reports_course__list']
                     },
                     {
                        id: 'test',
                        title: 'Mock Test',
                        navLink: '/reports/test',
                        permissions: ['reports_mockTest__list']
                     },
                     {
                        id: 'exercise',
                        title: 'Exercise',
                        navLink: '/reports/exercise',
                        permissions: ['reports_exercise__list']
                     },
                     {
                        id: "mentors",
                        title: "Mentors",
                        navLink: "/reports/mentors",
                        permissions: ['reports_mentor__list']
                      },
                     {
                        id: 'learners',
                        title: 'Learners',
                        navLink: '/reports/learners',
                        permissions: ['reports_learners__list']
                     },
                     {
                        id: 'live-attendance',
                        title: 'Live attendance',
                        navLink: '/reports/attendance',
                        permissions: ['reports_attendance__list']
                     },
                     {
                        id: 'transaction',
                        title: 'Transaction',
                        navLink: '/reports/transaction',
                        permissions: ['reports_transaction__list']
                     },
                     {
                        id: 'subscription',
                        title: 'Subscription',
                        navLink: '/reports/subscription',
                        permissions: ['reports_subscription__list']
                     },
                     {
                        id: 'batches',
                        title: 'Batches',
                        navLink: '/reports/batches',
                        permissions: ['reports']
                     },
                     {
                        id: 'export',
                        title: 'Export History',
                        navLink: '/reports/export',
                        permissions: ['reports']
                     },
                     {
                        id: 'storage-details-report',
                        title: 'Storage details',
                        navLink: '/reports/storage-details',
                        permissions: ['storageDetails']
                     },
                  ],
               },
               {
                  id: 'logs',
                  title: 'Logs',
                  icon: <Search set='curved' className='remix-icon' />,
                  navLink: '/logs',
                  permissions: ['logs']
               },
               {
                  id: 'reported-questions',
                  title: 'Reported Questions',
                  icon: <Chat set='curved' className='remix-icon' />,
                  navLink: '/reported-questions',
                  permissions: ['reportedQuestions']
               },
               {
                  id: 'notifications',
                  title: 'Notifications',
                  icon: <Notification set='curved' className='remix-icon' />,
                  navLink: '/notifications',
                  permissions: ['notifications']
               },
               {
                  id: "discussion",
                  title: "Discussion",
                  icon: <TwoUsers set="curved" className="remix-icon" />,
                  navLink: "/client/discussion/chapters",
                  permissions: ['discussions']
               }
            ];
            setPages(filterByPermissions(pages, accessControlData));
         }
      }

      return () => {
         setPages([]);
      };
   }, [accessControlData.role]);

  return pages;
}

export default useVerticalRoutes;


