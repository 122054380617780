import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo/logorays.webp";

export default function MenuLogo(props) {
  const webLogo =  localStorage.getItem('logo');

  return (
    <Link
    to="/dashboard"
    className="da-header-logo da-d-flex"
    onClick={props.onClose}
  >

    {/* <img className="da-logo" src={logo} alt="logo"/> */}
    
    <img width="85%" height="100%" src={webLogo} alt=""/>
  

    {/* <span className="h3 d-font-weight-800 da-text-color-primary-1 da-mb-6">.</span> */}

    <span
      className="da-p1-body da-font-weight-500 da-text-color-black-40 da-mb-16 da-ml-4"
      style={{
        letterSpacing: -1.5
      }}
    >
    </span>
  </Link>
  );
};