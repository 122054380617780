import { Avatar, Col, Dropdown, Menu, Row } from "antd";
import { Logout, User } from "react-iconly";
import { Link, useHistory } from "react-router-dom";
import Notification from "view/components/engagespot";
import avatarImg from "../../../assets/images/memoji/memoji-1.png";
import { useContext, useEffect } from "react";
import { AccessControl } from "context/access-control.context";
import { User as UserContext } from "context/user.context";

export default function HeaderUser() {
  const history = useHistory();
  const accessControlData = useContext(AccessControl);
  const user = useContext(UserContext);

  const handleLogout = () => {
    localStorage.clear();
    history.push("/auth/login");
  };

  const menu = (
    <Menu theme={"light"}>
      {accessControlData.role !== "client" && (
        <Menu.Item
          key={0}
          icon={
            <User
              set="curved"
              className="remix-icon da-vertical-align-middle da-text-color-dark-0"
              size={16}
            />
          }
          className="da-text-color-dark-0"
        >
          <Link to="/profile">Profile</Link>
        </Menu.Item>
      )}

      <Menu.Item
        key={5}
        onClick={handleLogout}
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle da-text-color-dark-0"
            size={16}
          />
        }
        className="da-text-color-dark-0"
      >
        <button className="csm-reset-button ">Logout</button>
      </Menu.Item>
      {/* <Menu.Item key={8}>
        <Notification />
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Row>
      {accessControlData.role === "faculty" && (
        <Col className="da-d-flex-center da-mr-10">
          <Notification />
        </Col>
      )}
      <Dropdown overlay={menu} span={20} placement="bottomLeft">
        <Col className="da-d-flex-center" onClick={(e) => e.preventDefault()}>
        {
          user?.id &&
          <Avatar 
            src={user?.profile_details?.profile_picture || avatarImg} 
            size={40} 
          />
        }
        </Col>
      </Dropdown>
    </Row>
  );
}
