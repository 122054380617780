import axios from "axios";

export const ListSegmentsApiService = (data = {}) => {
  return axios.get(`segments`, {
    paginate: data.paginate || 0,
  });
};
export const ListSubSegmentsApiService = (id, data = {}) => {
  return axios.get(`segments/${id}/sub-segments`, {
    paginate: data.paginate || 0,
  });
};

export const ListSubscriptionsApiService = (data = {}) => {
  return axios.get(`subscriptions`, {
    paginate: data.paginate || 0,
  });
};

export const GetSegmentApiService = (id) => {
  return axios.get(`segments/${id}`);
};

export const CreateSegmentApiService = (data) => {
  return axios.post(`segments`, data);
};

export const ListBatchesApiServiceV2 = () => {
  return axios.get(`v2/segments/batches`);
};
export const ListBatchesApiService = () => {
  return axios.get(`segments/batches`);
};

export const UpdateSegmentApiService = (id, data) => {
  return axios.post(`segments/${id}`, data);
};

export const DeleteSegmentApiService = (id) => {
  return axios.delete(`segments/${id}`);
};

export const AddItemsSegmentApiService = (id, data) => {
  return axios.post(`segments/${id}/add-items`, data);
};

export const RemoveItemsSegmentApiService = (id, data) => {
  return axios.post(`segments/${id}/remove-items`, data);
};

export const GetSegmentByIdApiService = (id, data) => {
  return axios.get(`segments/${id}`, data);
};

export const ReorderSegmentProductApiService = (id, data) => {
  return axios.post(`segments/${id}/order-products`, data);
};
export const ReorderSegmentTestApiService = (id, data) => {
  return axios.post(`segments/${id}/order-tests`, data);
};
export const ReorderSegmentSubscriptionApiService = (id, data) => {
  return axios.post(`segments/${id}/order-subscriptions`, data);
};

export const ViewSegmentByIdApiService = (id, data) => {
  return axios.get(`segments/${id}/view`, data);
};
export const ViewSegmentBatchesByIdApiService = (id) => {
  // return axios.get(`segments/${id}/batches`);
  const segment_ids = Array.isArray(id) ? id.join(",") : id;
  
  return axios.get(
     `segments/batches`, {
      params: {
       ...(Boolean(id) && segment_ids && { segment_ids }) 
      }
     }
  );
};
export const CreateSegmentBatchesByIdApiService = (id, data) => {
  return axios.post(`segments/${id}/batches`, data);
};
export const UpdateSegmentBatchesByIdApiService = (id, batchId, data) => {
  return axios.post(`segments/${id}/batches/${batchId}`, data);
};
export const RemoveSegmentBatchesByIdApiService = (id, batchId) => {
  return axios.delete(`segments/${id}/batches/${batchId}`);
};
export const ListStudentsSegmentBatchesByIdApiService = (key, id, params) => {
  return axios.get(`users/?${key}=${id}`, { params });
};

export const AssignStudentsSegmentBatchesByIdApiService = (
  id,
  batchId,
  data
) => {
  return axios.post(`segments/${id}/batches/${batchId}/add-users`, data);
};
export const RemoveStudentsSegmentBatchesByIdApiService = (
  id,
  batchId,
  data
) => {
  return axios.post(`segments/${id}/batches/${batchId}/remove-users`, data);
};

export const AssignedItemsToBatch = (segmentId, data) => {
  return axios.post(`segments/${segmentId}/batches/add-assigned-items`, data);
};

export const ListAssignedBatchOfProduct = (segmentId, productId) => {
  // return axios.get(`segments/${segmentId}/batches?private_product_id=${productId}`);
  return axios.get(
    `segments/batches?private_product_id=${productId}&segment_ids=${
      Array.isArray(segmentId) ? segmentId.join(",") : segmentId
    }`
  );
};
export const ListAssignedBatchOfPrivateTests = (segmentId, testId) => {
  return axios.get(`segments/${segmentId}/batches?private_test_id=${testId}`);
};
export const RemoveAssignedBatchOfProduct = (segmentId, data) => {
  return axios.post(
    `segments/${segmentId}/batches/remove-assigned-items`,
    data
  );
};

export const ReorderSegmentApiService = (data) => {
  return axios.post(`segments/order`, data);
};
