import axios from "axios";

export const ListMockTestSections = (testId, data) => {
  return axios.get(`tests/${testId}/sections`, {
    params: {
      paginate: data.paginate || 0,
      page: data.page || 1,
      per_page: data.per_page || 10,
      segment_id: data.segment_id || undefined,
    },
  });
};
export const CreateMockTestSection = (testId, data) => {
  return axios.post(`tests/${testId}/sections`, data);
};

export const GetMockTestSection = (testId, sectionId) => {
  return axios.get(`tests/${testId}/sections/${sectionId}`);
};

export const UpdateMockTestSection = (testId, sectionId, data) => {
  return axios.post(`tests/${testId}/sections/${sectionId}`, data);
};

export const DeleteMockTestSection = (testId, sectionId) => {
  return axios.delete(`tests/${testId}/sections/${sectionId}`);
};

export const ReorderMockTestSection = (testId, data) => {
  return axios.post(`tests/${testId}/sections/order`, data);
};

export const ListQuestionsMockTestSection = (
  testId,
  sectionId,
  page,
  perPage
) => {
  return axios.get(
    `tests/${testId}/sections/${sectionId}/list-questions?page=${page}&per_page=${perPage}`
  );
};

export const AddQuestionMockTestSection = (testId, sectionId, data) => {
  return axios.post(
    `tests/${testId}/sections/${sectionId}/add-questions`,
    data
  );
};
export const RemoveQuestionMockTestSection = (testId, sectionId, data) => {
  return axios.post(
    `tests/${testId}/sections/${sectionId}/remove-questions`,
    data
  );
};

export const ReorderQuestionsMockTestSection = (testId, sectionId, data) => {
  return axios.post(
    `tests/${testId}/sections/${sectionId}/order-questions`,
    data
  );
};

// export const ListTestsApiService = (data = {}) => {
//     return axios.get(`tests`, {
//         params: {
//             paginate: data.paginate || 0,
//             page: data.page || 1,
//             per_page: data.per_page || 10,
//             segment_id: data.segment_id || undefined
//         },
//     });
// };
