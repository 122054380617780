import axios from "axios";

export const LoginApiService = (data) => {
  return axios.post(`auth/login`, {
    email: data.email,
    password: data.password,
    client_id: process.env.REACT_APP_API_CLIENT_ID,
  });
};

export const ForgotPasswordApiService = (data) => {
  return axios.post(`auth/forgot-password`, {
    email: data.email,
  });
};

export const ResetPasswordApiService = (data) => {
  return axios.post(`auth/reset-password`, {
    email: data.email,
    token: data.token,
    password: data.password,
    password_confirmation: data.password_confirmation,
  });
};

export const GenerateResetPasswordToken = (data) => {
  return axios.post(`auth/generate-token/${data?.id}`, {
    email: data.email,
    token: data.token,
    password: data.password,
    password_confirmation: data.password_confirmation,
  });
};
