import * as actionTypes from './generalTypes';

const INITIAL_STATE = {
   sectionMockTests: null,
   user: null,
   reviewable_question: null,
   faculity_mocktest_solution_marks: null,
};

const generalReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case actionTypes.GENERAL_SECTION_MOCK_TESTS:
         return {
            ...state,
            sectionMockTests: action.payload,
         };

      case actionTypes.GENERAL_USER:
         return {
            ...state,
            user: action.payload,
         };

      case actionTypes.GENERAL_REVIEWABLE_QUESTION:
         return {
            ...state,
            reviewable_question: action.payload,
         };

      case actionTypes.GENERAL_FACULITY_MOCKTEST_SOLUTION_MARKS:
         return {
            ...state,
            faculity_mocktest_solution_marks: action.payload,
         };

      default:
         return state;
   }
};

export default generalReducer;
