import { message } from 'antd';
import { Toaster } from './../services/toaster.service';
import { RiCheckboxCircleLine } from 'react-icons/ri';
import { errorMessageParser } from './errorParser';
import { UploadImageApiService } from 'services/api';
import { format, parse } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const showToast = ({
   type = 'success',
   title = 'Success',
   message = 'Operation Successful',
   defaultToErrorMsg = false,
}) => {
   if (defaultToErrorMsg === true) {
      type = 'error';
      title = 'Error';

      if (message === 'Operation Successful') {
         message = 'Operation Failed';
      }
   }

   let toaster = new Toaster(type);
   toaster.title = title;
   toaster.message = message;
   toaster.open();
};

export const isFileGreaterThanLimit = (file, limit = 5) => {
   if (!file) {
      showToast({ defaultToErrorMsg: true, message: 'No File Selected' });
      return false;
   }

   const mbLimit = limit * 1024 * 1024;

   if (file.size > mbLimit) {
      return true;
   } else {
      return false;
   }
};

export function isDeepEqualObjects(x, y) {
   const ok = Object.keys,
      tx = typeof x,
      ty = typeof y;
   return x && y && tx === 'object' && tx === ty
      ? ok(x).length === ok(y).length && ok(x).every((key) => isDeepEqualObjects(x[key], y[key]))
      : x === y;
}

export function isObject(o) {
   return o instanceof Object && o.constructor === Object;
}

export const addOrRemoveIfDataExists = ({
   record,
   addToPreview,
   setAddToPreview,
   dataType = 'data',
   showMessage = true,
}) => {
   const alreadyAdded = addToPreview.find((previewQuestions) => previewQuestions.id === record.id);

   if (alreadyAdded) {
      const filteredAddToPreviewQuestions = addToPreview.filter(
         (previewQuestions) => previewQuestions.id !== record.id
      );

      setAddToPreview([...filteredAddToPreviewQuestions]);

      if (showMessage) {
         message.success({
            content: `${dataType}  removed`,
            icon: <RiCheckboxCircleLine className='remix-icon' />,
         });
      }
   } else {
      setAddToPreview((state) => [...state, record]);

      if (showMessage) {
         message.success({
            content: `${dataType} added`,
            icon: <RiCheckboxCircleLine className='remix-icon' />,
         });
      }
   }
};

export const normFile = (e) => {
   console.log("e", e)
   if (Array.isArray(e)) {
      return e;
   }
   return e && e.fileList;
};

export const imageUploadAndReturnUrl = async (image, imageName, params = {}) => {
   let file = image[0].originFileObj;

   if (!file) {
      showToast({ defaultToErrorMsg: true, message: 'No File Selected' });
      return null;
   }

   const limit = 5;
   const isGreater = isFileGreaterThanLimit(file);

   if (isGreater) {
      showToast({
         type: 'error',
         title: 'File Large',
         message: `File is larger than ${limit} mb`,
      });

      return null;
   }

   const imageFormData = new FormData();
   imageFormData.append(imageName, file);

   try {
      const res = await UploadImageApiService(imageFormData, params);
      let imageUrl = res.url;
      return imageUrl;
   } catch (err) {
      const errorMsg = errorMessageParser(err);
      message.error(errorMsg);
      return null;
   }
};

export const parsedDate = (date, dateFormat, toLocal = false) => {
   let parsedDate;
   if(toLocal) {
      parsedDate = toZonedTime(
         date,
         Intl.DateTimeFormat().resolvedOptions().timeZone
      );
   } else {
      parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'", new Date())
   }
   const day = format(parsedDate, dateFormat); 
   return day;
};

export const pipeDate = (date, format = 'MMM Do YYYY, h: mm: ss a') => {
   if (date === null || date === undefined) return '--'
   let _date = new Date(date);
   return moment(_date).format(format);
};
export const pipeShortDate = (date, format = 'DD-MMM-YYYY') => {
   if (date === null || date === undefined) return '--'
   let _date = new Date(date);
   return moment(_date).format(format);
};

export function hexToHSL(H) {
   // Convert hex to RGB first
   let r = 0,
      g = 0,
      b = 0;
   if (H.length == 4) {
      r = '0x' + H[1] + H[1];
      g = '0x' + H[2] + H[2];
      b = '0x' + H[3] + H[3];
   } else if (H.length == 7) {
      r = '0x' + H[1] + H[2];
      g = '0x' + H[3] + H[4];
      b = '0x' + H[5] + H[6];
   }
   // Then to HSL
   r /= 255;
   g /= 255;
   b /= 255;
   let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

   if (delta == 0) h = 0;
   else if (cmax == r) h = ((g - b) / delta) % 6;
   else if (cmax == g) h = (b - r) / delta + 2;
   else h = (r - g) / delta + 4;

   h = Math.round(h * 60);

   if (h < 0) h += 360;

   l = (cmax + cmin) / 2;
   s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
   s = +(s * 100).toFixed(1);
   l = +(l * 100).toFixed(1);

   //   return "hsl(" + h + "," + s + "%," + l + "%)";

   const primary1 = 'hsl(' + h + ',' + s + '%,' + l + '%)';
   const primary2 = 'hsl(' + h + ',' + (s - 4) + '%,' + (l + 11) + '%)';
   const primary3 = 'hsl(' + (h - 2) + ',' + (s + 2) + '%,' + (l + 17) + '%)';
   const primary4 = 'hsl(' + (h + 2) + ',' + (s - 3) + '%,' + (l + 31) + '%)';

   return [primary1, primary2, primary3, primary4];
}

export function convertHMS(value) {
   const d = Number(value);
   const h = Math.floor(d / 3600);
   const m = Math.floor((d % 3600) / 60);
   const s = Math.floor((d % 3600) % 60);

   const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours ') : '';
   const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes ') : '';
   const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
   return hDisplay + mDisplay + sDisplay;
}

export function shuffle(array) {
   let currentIndex = array.length,
      randomIndex;

   // While there remain elements to shuffle.
   while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
   }

   return array;
}

export const exportToCSV = (csvData, fileName) => {
   const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
   const fileExtension = '.xlsx';
   const ws = XLSX.utils.json_to_sheet(csvData);
   const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   const data = new Blob([excelBuffer], { type: fileType });
   FileSaver.saveAs(data, fileName + fileExtension);
};

export const randomColor = () => {
   const randomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
   };
   
   var h = randomInt(0, 360);
   var s = randomInt(75, 98);
   var l = randomInt(85, 90);

   return `hsl(${h},${s}%,${l}%)`;
};

export const roundNumbers = (number) => {
   if (isNaN(number)) return 0;

   if (number % 1 === 0) {
      return number;
   } else {
      return number.toFixed(2);
   }
};

export const secondToMinute = (second) => {
   const minute = Number(second / 60);
   const roundedMinute = roundNumbers(minute);
   return roundedMinute;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatBytes(bytes, decimals = 2) {
   if (!+bytes) return '0 Bytes'

   const k = 1000
   const dm = decimals < 0 ? 0 : decimals
   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

   const i = Math.floor(Math.log(bytes) / Math.log(k))

   return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


export { Countries } from './countries'