import axios from "axios";
/** Course  Start */

export const ListCourseApiService = (data = {}) => {
  return axios.get(`products`, {
    params: {
      paginate: data.paginate || 0,
      page: data.page || 1,
      per_page: data.per_page || 10,
      name: data.name || undefined,
      segment_id:
        data.segment_id ||
        (data.segment_id == "" ? undefined : data.segment_id),
    },
  });
};

export const ViewCourseApiService = (id) => {
  return axios.get(`products/${id}`);
};

export const AddCourseApiService = (data) => {
  return axios.post("products", data);
};

export const UpdateCourseApiService = (courseId, data) => {
  return axios.post(`products/${courseId}`, data);
};
export const DeleteCourseApiService = (courseId, params) => {
  return axios.delete(`products/${courseId}`, { params });
};

/** Course  End */

// ****************************************************************************** //

/** Chapter Start */

export const ViewChapterByCourseIdApiService = (id) => {
  return axios.get(`products/${id}`);
};
export const ViewChapterInCourseApiService = (id) => {
  return axios.get(`products/session/${id}`);
};

export const AddChapterToCourseApiService = (data) => {
  return axios.post(`chapters`, data);
};

export const UpdateChapterInCourseApiService = (chapterId, data) => {
  return axios.post(`chapters/${chapterId}`, data);
};

export const RemoveChapterFromCourseApiService = (productId, params) => {
  return axios.delete(`chapters/${productId}`, { params });
};

export const ChangeChapterOrderInCourseApiService = (params, data) => {
  const { id: productId } = params;
  return axios.post(`products/${productId}/order-chapters`, data);
};

export const UpdateChapterQRApiService = (data) => {
  return axios.post(`qr-codes`, data);
};

export const GetChapterById = (id) => {
  return axios.get(`chapters/${id}`);
};

/** Chapter End */

// ****************************************************************************** //

/** Lesson Start */

export const ViewAllLessonInChapterApiService = (chapterId) => {
  return axios.get(`chapters/${chapterId}/lessons`);
};

export const ViewLessonInChapterApiServices = (chapterId, lessonId) => {
  return axios.get(`chapters/${chapterId}/lessons/${lessonId}`);
};

export const AddLessonToChaptersApiService = (params, data) => {
  const { id: lessonId } = params;
  return axios.post(`chapters/${lessonId}/lessons`, data);
};

export const UpdateLessonInChapterApiService = (params, data) => {
  const { chapterId, selectedItemId: lessonId } = params;
  return axios.post(`chapters/${chapterId}/lessons/${lessonId}`, data);
};

export const GenerateOtpLessonInChapterApiService = (params, data) => {
  const { chapterId, lessonId } = params;
  return axios.post(
    `chapters/${chapterId}/lessons/${lessonId}/generate-otp`,
    data
  );
};

export const UploadFileLessonInChapterApiService = (chapterId, data) => {
  return axios.post(`chapters/${chapterId}/lessons/upload-file`, data);
};

export const UploadLiveClassFileLessonInChapterApiService = (
  chapterId,
  data
) => {
  return axios.post(
    `chapters/${chapterId}/lessons/upload-file/live-video`,
    data
  );
};

export const RemoveLessonInChapterApiService = (
  chapterId,
  lessonId,
  params
) => {
  return axios.delete(`chapters/${chapterId}/lessons/${lessonId}`, { params });
};

export const ChangeLessonInChaptersApiService = (params, data) => {
  const { id: chapterId } = params;
  return axios.post(`chapters/${chapterId}/lessons/order`, data);
};

export const uploadLessonFile = (chapterId, data) => {
  return axios.post(`chapters/${chapterId}/lessons/upload-file`, data);
};

export const ListLessonQuestionsApiService = (chapterId, lessionId, data) => {
  return axios.get(`chapters/${chapterId}/lessons/${lessionId}/questions`);
};

export const AddLessonQuestionsApiService = (chapterId, lessionId, data) => {
  return axios.post(
    `chapters/${chapterId}/lessons/${lessionId}/add-questions`,
    data
  );
};
export const RemoveLessonQuestionsApiService = (chapterId, lessionId, data) => {
  return axios.post(
    `chapters/${chapterId}/lessons/${lessionId}/remove-questions`,
    data
  );
};
export const ReorderLessonQuestionsApiService = (
  chapterId,
  lessionId,
  data
) => {
  return axios.post(
    `chapters/${chapterId}/lessons/${lessionId}/order-questions`,
    data
  );
};

export const ListLessonQrCodesApiService = (chapterId, lessionId) => {
  return axios.get(`chapters/${chapterId}/lessons/${lessionId}/qr-codes`);
};

export const AddLessonQrCodesApiService = (data) => {
  return axios.post(`qr-codes`, data);
};

export const UpdateLessonQrCodesApiService = (id, data) => {
  return axios.post(`qr-codes/${id}`, data);
};

export const RemoveLessonQrCodesApiService = (id) => {
  return axios.delete(`qr-codes/${id}`);
};

export const ListExistingVideosApiService = (params = {}) => {
  return axios.get(`/lessons/videos`, { params: { ...params } });
};

export const ListLiveVideosApiService = (params) => {
  return axios.get(`/lessons/live-videos`, { params });
};

export const UpdateExistingVideosApiService = (params, data) => {
  const { chapterId, lessonId } = params;
  return axios.post(`/chapters/${chapterId}/lessons/${lessonId}`, data);
};

export const GetCompletedStudentsLiveClass = (chapterId, lessonId, params) => {
  return axios.get(
    `chapters/${chapterId}/lessons/${lessonId}/completed-users`,
    { params: { ...params } }
  );
};

export const VideoLessonSubtitleApiServices = (lessonId, videoId) => {
  return axios.get(`lessons/${lessonId}/subtitles?video_id=${videoId}`);
};
export const AddVideoLessonSubtitleApiServices = (lessonId, data) => {
  return axios.post(`lessons/${lessonId}/subtitles`, data);
};
export const RemoveVideoLessonSubtitleApiServices = (lessonId, data) => {
  return axios.post(`lessons/${lessonId}/subtitles/delete`, data);
};
export const SetAsDefaultVideoLessonSubtitleApiServices = (lessonId, data) => {
  return axios.post(`lessons/${lessonId}/subtitles/makeDefault`, data);
};

export const GetSameVideoLessons = (chapterId, lessonId) => {
  return axios.get(`chapters/${chapterId}/lessons/${lessonId}/same-lessons`);
};
export const DeleteSameVideoLessons = (chapterId, lessonId) => {
  return axios.delete(
    `chapters/${chapterId}/lessons/${lessonId}?soft_delete=1`
  );
};

export const DeleteMultipleStorageDetails = (data) => {
  return axios.post(`lessons/remove`, { lesson_ids: data });
};
/** Lesson End */
