import axios from 'axios';
import { isObject } from './index';
import { showToast } from 'helpers';

const findErrorMessage = (entity) => {
   let errorMessage = 'Something went wrong';

   if (!entity) {
      return errorMessage;
   } else if (isObject(entity)) {
      const firstKey = Object.keys?.(entity)?.[0];
      const firstElement = entity?.[firstKey];
      return findErrorMessage(firstElement);
   } else if (Array.isArray(entity)) {
      return findErrorMessage(entity?.[0]);
   } else if (typeof entity === 'string') {
      return entity;
   } else {
      return errorMessage;
   }
};

export const errorMessageParser = (e) => {
   let errorMessage = 'Something went wrong';

   //    if directly errorMessage is sent to us
   if (typeof e === 'string') {
      return e;
   }

   if (!axios.isAxiosError(e)) {
      // native error

      if (e.message) {
         errorMessage = findErrorMessage(e.message);
         return errorMessage;
      }

      if (e?.messgae) {
         errorMessage = e?.messgae;
         return errorMessage;
      }

      if (e.error) {
         errorMessage = findErrorMessage(e.error);
         return errorMessage;
      }

      return errorMessage;
   } else {
      //   axios error

      const data = e.response?.data;

      if (data?.error) {
         errorMessage = findErrorMessage(data?.error);
         return errorMessage;
      }

      if (data?.errors) {
         errorMessage = findErrorMessage(data?.errors);
         return errorMessage;
      }

      if (data?.message) {
         errorMessage = data?.message;
         return errorMessage;
      }

      if (data?.messgae) {
         errorMessage = data?.messgae;
         return errorMessage;
      }

      // no case matches
      return errorMessage;
   }
};

export const getErrorMsgAndShowToast = (e) => {
   const errorMsg = errorMessageParser(e);
   showToast({ defaultToErrorMsg: true, message: errorMsg });
};
