import * as actionTypes from './previewTypes';

const INITIAL_STATE = {
   testConstraints: null,
   testPreview: null,
   questions: null,
   chapters:null
};

const previewReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case actionTypes.TEST_PREVIEW_OVERVIEW:
         return {
            ...state,
            testPreview: action.payload,
         };

      case actionTypes.TEST_PREVIEW_QUESTIONS:
         return {
            ...state,
            questions: action.payload,
         };

      case actionTypes.TEST_PREVIEW_CONSTRAINTS:
         return {
            ...state,
           testConstraints: action.payload,
         };

      case actionTypes.TEST_PREVIEW_CHAPTERS:
         return {
            ...state,
            chapters: action.payload,
         };

      case actionTypes.TEST_PREVIEW_RESET:
         return {
            ...INITIAL_STATE,
         };

      default:
         return state;
   }
};

export default previewReducer;
