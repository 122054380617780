import React, { Suspense } from 'react';

import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { Provider } from 'react-redux';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize);
import store from './redux/store';
import 'react-quill/dist/quill.snow.css';
import './assets/icons/remixicon.css';
import './assets/less/yoda-theme.less';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

var fontSizeStyle = Quill.import('attributors/style/size');
fontSizeStyle.whitelist = ['8px', '9px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '36px', '48px', '60px'];
Quill.register(fontSizeStyle, true);

import App from './App';

import axios from 'axios';

axios.interceptors.request.use(
   (config) => {
      const accessToken = localStorage.access_token;

      config.baseURL = process.env.REACT_APP_API_URL;
      config.headers['X-App-Type'] = 'client';
      if (accessToken) {
         config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
   },
   (err) => Promise.reject(err)
);

axios.interceptors.response.use(
   (res) => {
      let response = res.data;
      return response;
   },
   (err) => {
      if (err.response) {
         if (err.response.status === 401 && err.response.config.url !== 'auth/login') {
            localStorage.clear();
            window.location.href = '/auth/login';
         }
      }
      throw err.response?.data;
   }
);

let persistor = persistStore(store);

ReactDOM.render(
   <Suspense fallback='loading'>
      <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
               <App />
            </BrowserRouter>
         </PersistGate>
      </Provider>
   </Suspense>,
   document.getElementById('root')
);
