const PRIMARY_KEY = 'CUSTOMISE_';

export const THEME = PRIMARY_KEY + 'THEME';
export const CONTENT_WIDTH = PRIMARY_KEY + 'CONTENT_WIDTH';
export const SIDEBAR_COLLAPSED = PRIMARY_KEY + 'SIDEBAR_COLLAPSED';
export const SIDEBAR_COLLAPSE_BUTTON = PRIMARY_KEY + 'SIDEBAR_COLLAPSE_BUTTON';
export const LAYOUT_CHANGE = PRIMARY_KEY + 'LAYOUT_CHANGE';
export const NAVIGATION_FULL = PRIMARY_KEY + 'NAVIGATION_FULL';
export const NAVIGATION_BG = PRIMARY_KEY + 'NAVIGATION_BG';
export const DIRECTION = PRIMARY_KEY + 'DIRECTION';
