import { lazy } from "react";

const useRoutePages = () => {
  let routes = [
    {
      path: "/faculty/assigned-lessons",
      component: lazy(() =>
        import("../../view/pages/faculty/assigned-lessons")
      ),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/availability",
      component: lazy(() => import("../../view/pages/bookings/Availability")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/bookings",
      component: lazy(() => import("../../view/pages/bookings")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/google-connect",
      component: lazy(() => import("../../view/pages/bookings/GoogleConnect")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/question-bank/questions",
      component: lazy(() => import("../../view/pages/questions")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/questions/edit",
      component: lazy(() => import("../../view/pages/questions/question-edit")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/question-bank",
      component: lazy(() => import("../../view/pages/question-bank")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/question-bank/chapters",
      component: lazy(() =>
        import("../../view/pages/question-bank/chapters-list")
      ),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: null,
    },
    {
      path: "/segment",
      component: lazy(() => import("../../view/pages/segment")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/segment/details/:id",
      component: lazy(() =>
        import("../../view/pages/segment/segment-list/segment-dashboard")
      ),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/products-segment-details/:id",
      // component: lazy(() => import('../../view/pages/segment/segment-list/segment-dashboard')),
      component: lazy(() =>
        import("../../view/pages/segment/segment-list/segment-products")
      ),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/faculty",
      component: lazy(() => import("../../view/pages/faculty")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/faculty/:id",
      component: lazy(() => import("../../view/pages/faculty/faculty-session")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/mock-test/settings/:id",
      component: lazy(() => import("../../view/pages/tests/test-settings")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/mock-test/:id",
      component: lazy(() => import("../../view/pages/tests/test-view")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/mock-download/:id",
      component: lazy(() => import("../../view/pages/tests/test-download")),
      layout: "FullLayout",
      breadCrumbs: null,
    },
    {
      path: "/mock-solution-download/:id",
      component: lazy(() =>
        import("../../view/pages/tests/test-download/solution")
      ),
      layout: "FullLayout",
      breadCrumbs: null,
    },
    {
      path: "/test/preview/:spec",
      component: lazy(() => import("../../view/pages/tests/test-preview")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/mock-test",
      component: lazy(() => import("../../view/pages/tests")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/test-series/:id",
      component: lazy(() =>
        import("../../view/pages/test-series/testSeries-view")
      ),
      layout: "VerticalLayout",
      breadCrumbs: ["/test-series"],
    },
    {
      path: "/test-series",
      component: lazy(() => import("../../view/pages/test-series")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/course/:id",
      component: lazy(() =>
        import("../../view/pages/course/course-view/index")
      ),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: ["/course"],
    },
    {
      path: "/course",
      component: lazy(() => import("../../view/pages/course")),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: null,
    },
    {
      path: "/chapter/:id",
      component: lazy(() => import("../../view/pages/course/chapter-view")),
      layout: "VerticalLayout",
      breadCrumbs: ["/course", 0],
    },
    {
      path: "/lesson",
      component: lazy(() =>
        import("../../view/pages/course/course-view/lesson-view")
      ),
      layout: "VerticalLayout",
      breadCrumbs: ["/course", 0, 0],
    },
    {
      path: "/section/:id",
      component: lazy(() =>
        import("../../view/pages/test-series/section-view")
      ),
      layout: "VerticalLayout",
      breadCrumbs: ["/test-series", 0, 0],
    },
    {
      path: "/profile",
      component: lazy(() => import("../../view/pages/profile")),
      layout: "VerticalLayout",
      protected: true,
      breadCrumbs: null,
    },
    {
      path: "/auth/forgot-password",
      component: lazy(() =>
        import("../../view/pages/authentication/forgot-password")
      ),
      layout: "FullLayout",
      breadCrumbs: null,
    },
    {
      path: "/auth/reset-password",
      component: lazy(() =>
        import("../../view/pages/authentication/reset-password")
      ),
      layout: "FullLayout",
      breadCrumbs: null,
    },
    {
      path: "/auth/login",
      component: lazy(() => import("../../view/pages/authentication/login")),
      layout: "FullLayout",
      breadCrumbs: null,
    },
    {
      path: "/pages/blank-page",
      component: lazy(() => import("../../view/pages/blank")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/pages/error-page",
      component: lazy(() => import("../../view/pages/error")),
      layout: "FullLayout",
      breadCrumbs: null,
    },
    {
      path: "/subscriptions",
      component: lazy(() => import("../../view/pages/subscriptions")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/subscriptions-details/:id",
      component: lazy(() =>
        import("../../view/pages/subscriptions/subscription-details")
      ),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/banners",
      component: lazy(() => import("../../view/pages/banners")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: '/admin-users',
      component: lazy(() => import('../../view/pages/admin-users')),
      layout: 'VerticalLayout',
      breadCrumbs: null,
    },
    {
      path: '/roles-and-permissions',
      component: lazy(() => import('../../view/pages/roles-and-permissions')),
      layout: 'VerticalLayout',
      breadCrumbs: null,
    },
    {
      path: '/practice',
      component: lazy(() => import('../../view/pages/practice')),
      layout: 'VerticalLayout',
      breadCrumbs: null,
    },
    {
      path: "/cupons",
      component: lazy(() => import("../../view/pages/coupons")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/user-details",
      component: lazy(() => import("../../view/pages/user-details")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/practice",
      component: lazy(() => import("../../view/pages/practice")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/exercises/:exerciseId",
      component: lazy(() =>
        import("../../view/pages/question-bank/exercise-list")
      ),
      layout: "VerticalLayout",
      exact: true,
    },
    {
      path: "/exercises/:id/questions",
      component: lazy(() => import("../../view/pages/tests/test-view")),
      layout: "VerticalLayout",
      exact: true,
    },
    {
      path: "/chapters/:id/excersises/:excersiseId",
      component: lazy(() => import("../../view/pages/tests/test-view")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/course/:productId/chapter/:id/excersises/:excersiseId",
      component: lazy(() => import("../../view/pages/tests/test-view")),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: null,
    },
    {
      // Not using
      path: "/chapters/:id/lesson/:lessonId/questions",
      component: lazy(() =>
        import("../../view/pages/course/course-view/questions")
      ),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      // Not using
      path: "/chapters/:id/lesson/:lessonId/add-questions",
      component: lazy(() =>
        import("../../view/pages/course/course-view/questions/add-questions")
      ),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/dashboard",
      component: lazy(() => import("../../view/pages/dashboard")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/app-customization",
      component: lazy(() => import("../../view/pages/app-customization")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/app-integration",
      component: lazy(() => import("../../view/pages/integration")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    // {
    //   path: "/faculty/assigned-lessons",
    //   component: lazy(() =>
    //     import("../../view/pages/faculty/assigned-lessons")
    //   ),
    //   layout: "VerticalLayout",
    //   breadCrumbs: null,
    //   exact: true,
    // },
    {
      path: "/faculty/discussion/chapters/:id/reply/:replyId",
      component: lazy(() =>
        import("../../view/pages/faculty/discussion/reply")
      ),
      layout: "VerticalLayout",
      breadCrumbs: [0],
      exact: true,
    },
    {
      path: "/faculty/discussion/chapters/:id",
      component: lazy(() => import("../../view/pages/faculty/discussion")),
      layout: "VerticalLayout",
      breadCrumbs: ["/faculty/discussion/chapters"],
      exact: true,
    },
    {
      path: "/faculty/discussion/chapters",
      component: lazy(() =>
        import("../../view/pages/faculty/discussion/chapters")
      ),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/client/discussion/chapters/:id/reply/:replyId",
      component: lazy(() => import("../../view/pages/discussion/reply")),
      layout: "VerticalLayout",
      breadCrumbs: [0],
      exact: true,
    },
    {
      path: "/client/discussion/chapters/:id",
      component: lazy(() => import("../../view/pages/discussion")),
      layout: "VerticalLayout",
      breadCrumbs: ["/faculty/discussion/chapters"],
      exact: true,
    },
    {
      path: "/client/discussion/chapters",
      component: lazy(() => import("../../view/pages/discussion/chapters")),
      layout: "VerticalLayout",
      breadCrumbs: null,
      exact: true,
    },
    {
      path: "/reported-questions",
      component: lazy(() => import("../../view/pages/reported-questions")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/logs",
      component: lazy(() => import("../../view/pages/logs")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/qr-codes",
      component: lazy(() => import("../../view/pages/qr-codes")),
      layout: "VerticalLayout",
      breadCrumbs: ["/course", 0, 0],
    },
    {
      path: "/reports/transaction",
      component: lazy(() => import("../../view/pages/report/transaction")),
      layout: "VerticalLayout",
      breadCrumbs: ["/reports/transaction"],
    },
    {
      path: "/reports/subscription",
      component: lazy(() => import("../../view/pages/report/subscription")),
      layout: "VerticalLayout",
      breadCrumbs: ["/reports/subscription"],
    },
    {
      path: "/reports/learners",
      component: lazy(() => import("../../view/pages/report/learners")),
      layout: "VerticalLayout",
      breadCrumbs: ["/reports/learners"],
    },
    {
      path: "/reports/batches",
      component: lazy(() => import("../../view/pages/report/batches")),
      layout: "VerticalLayout",
      breadCrumbs: ["/reports/batches"],
    },
    {
      path: "/reports/test",
      component: lazy(() => import("../../view/pages/report/test")),
      layout: "VerticalLayout",
      breadCrumbs: ["/reports/test"],
    },
    {
      path: "/reports/export",
      component: lazy(() => import("../../view/pages/report/export")),
      layout: "VerticalLayout",
      breadCrumbs: ["/reports/export"],
    },
    {
      path: "/notifications",
      component: lazy(() => import("../../view/pages/notifications")),
      layout: "VerticalLayout",
      breadCrumbs: null,
    },
    {
      path: "/reports/attendance",
      component: lazy(() => import("../../view/pages/report/attendance")),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: null,
    },
    {
      path: "/reports/attendance/:classId/students",
      component: lazy(() =>
        import("../../view/pages/report/attendance/list-students")
      ),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: null,
    },
    {
      path: "/reports/mentors",
      component: lazy(() => import("../../view/pages/report/mentor")),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: null,
    },
    {
      path: "/reports/course",
      component: lazy(() => import("../../view/pages/report/course")),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: null,
    },
    {
      path: "/reports/course/public/:courseId",
      component: lazy(() =>
        import("../../view/pages/report/course/PublicCourse")
      ),
      layout: "VerticalLayout",
      exact: true,
    },
    {
      path: "/reports/course/private/:courseId",
      component: lazy(() =>
        import("../../view/pages/report/course/PrivateCourse")
      ),
      layout: "VerticalLayout",
      exact: true,
    },
    {
      path: "/reports/course/:courseId",
      component: lazy(() =>
        import("../../view/pages/report/course/report-of-course")
      ),
      layout: "VerticalLayout",
      exact: true,
    },
    {
      path: "/reports/storage-details",
      component: lazy(() => import("../../view/pages/report/storage-details")),
      layout: "VerticalLayout",
      exact: true,
    },
    {
      path: "/segment/:id/subsegments",
      component: lazy(() => import("../../view/pages/segment/subsegments")),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: null,
    },
    {
      path: "/mock-test/:id/sections",
      component: lazy(() => import("../../view/pages/tests/sections")),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: ["/mock-test"],
    },
    {
      path: "/mock-test/:id/section/:sectionId/questions",
      component: lazy(() =>
        import("../../view/pages/tests/sections/questions")
      ),
      layout: "VerticalLayout",
      exact: true,
      breadCrumbs: ["/mock-test", 0, 0],
    },
    {
      path: "/reports/exercise",
      component: lazy(() => import("../../view/pages/report/exercise")),
      layout: "VerticalLayout",
      breadCrumbs: ["/reports/exercise"],
    },
  ];

  return routes;
};

export default useRoutePages;
